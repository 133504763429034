import { render, staticRenderFns } from "./PageAccessClassroom.vue?vue&type=template&id=142d235d&scoped=true&"
import script from "./PageAccessClassroom.vue?vue&type=script&lang=js&"
export * from "./PageAccessClassroom.vue?vue&type=script&lang=js&"
import style0 from "./PageAccessClassroom.vue?vue&type=style&index=0&id=142d235d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142d235d",
  null
  
)

export default component.exports